<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('users.userList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="hasAction('create_user') || isAdmin() || isSuperAdmin() || isAccountant()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddUser(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="userHeaders"
            :items="userList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td
                class="cursor-pointer"
                @mouseover="onHoverEmail(item)"
                @mouseleave="onLeaveEmail(item)"
              >
                <span
                  class="pb-1"
                  style="border-bottom: 1px dashed #5cb860;">{{ item.fullname }}</span>
                <div
                  v-show="item.isShowTool"
                  class="mt-1"
                  style="display: flex;">
                  <span
                    class="text-success hover-detail-text"
                    @click="onShowModalViewDetailUser(item)">{{ $t('users.viewDetail') }}</span>
                  <span
                    class="mx-2">|</span>
                  <span
                    class="hover-delete-text"
                    style="color: blue;"
                    @click="onShowTransactionHistory(item.id)">{{ $t('common.history') }}</span>
                  <span
                    v-if="(hasAction('delete_user') && item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                    class="mx-2">|</span>
                  <span
                    v-if="(hasAction('delete_user') && item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                    class="hover-delete-text"
                    style="color: red;"
                    @click="onShowModalDeleteUser(item.id)">{{ $t('common.delete') }}</span>
                  <span
                    v-if="!item.isActive && (isAdmin() || isSuperAdmin())"
                    class="mx-2">|</span>
                  <span
                    v-if="!item.isActive && (isAdmin() || isSuperAdmin())"
                    class="hover-active-text"
                    @click="onActiveUser(item.id)">{{ $t('user.is_active') }}</span>
                </div>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.systemRole }}</td>
              <!-- <td>{{ $t(getGender(item.gender)) }}</td>
              <td>{{ item.dob }}</td>
              <td>{{ item.dateCreated }}</td> -->
              <td>
                <v-tooltip
                  v-if="!item.isActive"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on">mdi-close-outline</v-icon>
                  </template>
                  <span>{{ $t('users.state.in_active') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      dark
                      v-on="on">mdi-check-outline</v-icon>
                  </template>
                  <span>{{ $t('users.state.active') }}</span>
                </v-tooltip>
              </td>
              <td class="text-xs-center">
                <v-tooltip
                  v-if="isAdmin() || isSuperAdmin() || isAccountant() || isModerator()"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="item.isLoading"
                      class="mx-2"
                      fab
                      small
                      color="success"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalResetPassword(item)">
                      <v-icon dark>mdi-lock-reset</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('common.resetPassword') }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      :total="userPaginate.totalPage"
      :current-page="userPaginate.currentPage"
      :row-per-page="userPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addUserModal
      ref="modalAddUser"
      @getApiUserList="getUserList"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="modalConfirmTypeSelected == modalConfirmType.DELETE_USER ? onDeleteUser() : onResetPassword()"
    />
    <!-- transaction history -->
    <transactionHistoryListForUser
      ref="transactionHistoryListForUser"/>
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GenderType from 'enum/genderType'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import AddUserModal from 'Components/AddUserModal'
import Avatar from 'vue-avatar'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import RoleType from 'enum/roleType'
import sysGroupEnum from 'enum/sysGroupEnum'
import transactionHistoryListForUser from '../History/TransactionHistoryListForUser'

export default {
  components: {
    addUserModal: AddUserModal,
    Avatar,
    ConfirmModal,
    transactionHistoryListForUser
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      // {
      //   sortable: false,
      //   text: 'common.logo',
      //   value: 'avatar'
      // },
      {
        sortable: false,
        text: 'users.fullName',
        value: 'fullname'
      },
      {
        sortable: false,
        text: 'user.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'users.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'common.role',
        value: 'role'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'isActive'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    userList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    userSelected: {
      id: null
    },
    rolesSelected: [],
    isUpdate: true,
    dobDefault: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    selected: [],
    userSearch: {
      searchText: null
    },
    gender: stringUtils.GenderList,
    genderList: null,
    isLoading: false,
    isActive: false,
    modalConfirmType: {
      DELETE_USER: 1,
      FORGOT_PASSWORD_USER: 2
    },
    modalConfirmTypeSelected: null,
    userForgotPassSelected: null
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'USER_LIST_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    USER_LIST_DATA () {
      this.userPaginate.currentPage = this.USER_LIST_DATA.paginate.currentPage
      this.userPaginate.totalPage = this.USER_LIST_DATA.paginate.totalPage
      this.userPaginate.rowPerPage = this.USER_LIST_DATA.paginate.perPage
      let userList = this.USER_LIST_DATA.results
      this.userList = []
      for (let i = 0, size = userList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.USER_LIST_DATA.paginate.currentPage - 1) * this.USER_LIST_DATA.paginate.pageSize,
          // stt: i + 1,
          id: userList[i].id,
          username: userList[i].username,
          fullname: userList[i].last_name + ' ' + userList[i].first_name,
          phone: userList[i].profile && userList[i].profile.phone,
          gender: userList[i].profile && userList[i].profile.gender,
          dob: this.getDateOfBirth(userList[i].profile.dob),
          email: userList[i].email,
          isActive: userList[i].profile && userList[i].profile.is_active,
          dateCreated: this.getDateOfBirth(userList[i].date_created),
          isShowTool: false,
          systemRole: userList[i].group.name,
          systemRoleId: userList[i].group.id,
          isLoading: false
        }
        this.userList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    /**
     * Show modal reset password
     */
    onShowModalResetPassword: function (user) {
      this.userForgotPassSelected = user
      this.confirmModalTitle = this.$t('forgotPassword.confirmResetPassword')
      this.modalConfirmTypeSelected = this.modalConfirmType.FORGOT_PASSWORD_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Reset password
     */
    onResetPassword: function () {
      let filter = {
        email: this.userForgotPassSelected.email,
        // fromDomainType: this.userForgotPassSelected.numberOfEntity !== 0 ? LoginFromType.ADMIN : LoginFromType.CUSTOMER,
        domainWithBasePath: this.userForgotPassSelected.numberOfEntity !== 0 ? stringUtils.DOMAIN_BASE_PATH_ADMIN : stringUtils.CUSTOMER_URL
      }
      this.userForgotPassSelected.isLoading = true
      this.SEND_EMAIL_FORGOT_PASSWORD(filter).then(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('forgotPassword.pleaseCheckEmailToChangePassword'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Get entity role
     */
    getEntityRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Get system role
     */
    getSystemRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Active user
     */
    onActiveUser: function (userId) {
      this.ACTIVE_USER({ id: userId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.isLoading = true
          this.getUserList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Show modal view detail user
     */
    onShowModalViewDetailUser: function (item) {
      this.$refs.modalAddUser.onShowModalUpdateUser(this.userPaginate.currentPage, true, item)
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getUserList()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Show modal add user
     */
    onShowModalAddUser: function () {
      this.$refs.modalAddUser.onShowModal(this.userPaginate.currentPage, true)
    },
    /**
     * Show modal delete user
     */
    onShowModalDeleteUser: function (userId) {
      this.userSelected.id = userId
      this.confirmModalTitle = this.$t('user.confirmDeleteUserTitle')
      this.modalConfirmTypeSelected = this.modalConfirmType.DELETE_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete user
     */
    onDeleteUser: function () {
      let filter = {
        params: {
          entityId: sessionStorage.getItem('entityId')
        }
      }
      this.DELETE_USER({ id: this.userSelected.id, ...filter }).then(
        function () {
          this.userSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getUserList()
        }.bind(this)
      )
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(dob, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_DATE)
      }
      return null
    },
    /**
     * Get gender
     */
    getGender: function (gender) {
      if (gender === GenderType.MALE) {
        return 'userProfile.genderType.male'
      } else if (gender === GenderType.FEMALE) {
        return 'userProfile.genderType.female'
      } else {
        return 'userProfile.genderType.other'
      }
    },
    /**
     * Get user list
     */
    getUserList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          searchText: this.userSearch.searchText,
          excludeGroup: [sysGroupEnum.CUSTOMER]
        }
      }
      this.GET_USER_LIST(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getUserList()
    },
    onShowTransactionHistory: function (userId) {
      //
      this.$refs.transactionHistoryListForUser.onShowModal(userId, true)
    },
    ...mapActions(['GET_USER_LIST', 'UPDATE_USER_PROFILE', 'DELETE_USER', 'ON_SHOW_TOAST', 'ACTIVE_USER', 'SEND_EMAIL_FORGOT_PASSWORD'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
</style>
